import React from "react"
import { useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { useRole } from "../hooks/useRole"
import { ReactComponent as IconBack } from "../assets/ic_back.svg"
import { FormControl, FormControlLabel, Radio, RadioGroup, Select, FilledInput } from "@mui/material"
import { Dialog, DialogContent, Button, TextField, MenuItem, Checkbox } from "@mui/material"
import { useForm } from "react-hook-form"
import { useApolloClient } from "@apollo/client"
import { MyAppBar } from "../App"
import { useNotify } from "react-admin"
import { GET_LANGUAGES, GET_PRODUCT_CATEGORIES, GET_CURRENCIES } from "./queries"
import { ALIST } from "../constants"

type AddKolDialogProps = {
  open: boolean
  kolData: any
  onClose: () => void
  onSave: (data: any) => void
  onDelete: (data: any) => void
}

enum KolActions {
  VIEW = "view",
  EDIT = "edit",
  ADD = "add",
}

export const AddKolDialog: React.FC<AddKolDialogProps> = ({ open, onClose, onSave, onDelete, kolData }) => {
  const { handleSubmit, control } = useForm()
  const onSubmit = (data: any) => {
    onSave(data)
    onClose()
  }
  const { user, logout } = useAuth0()
  const client = useApolloClient()
  const role = useRole()

  let action = KolActions.VIEW
  let title = "KOL Details"

  const notify = useNotify()

  const [editKolData, setEditKolData] = useState<any>(kolData)

  const [selectedGender, setSelectedGender] = useState("male")

  const getLanguages = async () => {
    const { data } = await client.query({
      query: GET_LANGUAGES,
    })
    return data.language
  }

  const getCategories = async () => {
    const { data } = await client.query({
      query: GET_PRODUCT_CATEGORIES,
    })
    return data.product_category
  }

  const getCurrencies = async () => {
    const { data } = await client.query({
      query: GET_CURRENCIES,
    })
    return data.currency_category
  }

  const handleGenderChange = (hash: any, event: string) => {
    setSelectedGender(event)
    setEditKolData({ ...editKolData, gender: event })
  }

  const updateSocialPlatformHandle = (platform: string, handle: string) => {
    let socialPlatforms = editKolData?.kol_social_platforms ?? []
    const follower =
      socialPlatforms.find((socialPlatform: any) => socialPlatform.social_platform_code === platform)?.number_of_followers ?? 0
    socialPlatforms = socialPlatforms.filter((socialPlatform: any) => socialPlatform.social_platform_code !== platform)
    socialPlatforms.push({
      social_platform_code: platform,
      social_platform_handle: handle,
      number_of_followers: follower,
      social_platform_tier: tierOfKOL(follower),
    })

    setEditKolData({ ...editKolData, kol_social_platforms: socialPlatforms })
  }

  const updateSocialPlatformFollower = (platform: string, follower: number) => {
    let socialPlatforms = editKolData?.kol_social_platforms ?? []
    const handle =
      socialPlatforms.find((socialPlatform: any) => socialPlatform.social_platform_code === platform)?.social_platform_handle ??
      ""
    socialPlatforms = socialPlatforms.filter((socialPlatform: any) => socialPlatform.social_platform_code !== platform)
    socialPlatforms.push({
      social_platform_code: platform,
      social_platform_handle: handle,
      number_of_followers: follower,
      social_platform_tier: tierOfKOL(follower),
    })

    setEditKolData({ ...editKolData, kol_social_platforms: socialPlatforms })
  }

  const findSocialTier = (platform: string) => {
    let socialPlatforms = editKolData?.kol_social_platforms ?? []
    const follower =
      socialPlatforms.find((socialPlatform: any) => socialPlatform.social_platform_code === platform)?.number_of_followers ?? 0
    return tierOfKOL(follower)
  }

  function tierOfKOL(follower: any) {
    if (follower >= 1000000) return "Elite"
    if (follower >= 500000) return "Mega"
    if (follower >= 100000) return "Macro"
    if (follower >= 50000) return "Mid B"
    if (follower >= 15000) return "Mid C"
    if (follower >= 5000) return "Micro"
    if (follower >= 1000) return "Nano"
    return "KOC"
  }

  const resetData = () => {
    setSelectedLanguages([])
    setSelectedPrimaryCategories([])
    setSelectedSecondaryCategories([])
    setDropdownPrimaryCategoryOpen(false)
    setDropdownSecondaryCategoryOpen(false)
    setDropdownLanguageOpen(false)
    setDropdownCurrencyOpen(false)
  }

  //Languages
  const MAX_LANGUAGE_SELECTIONS = 4
  const NO_LANGUAGE_SELECTED = ["Select up to 4 languages"]
  const [dropdownLanguageOpen, setDropdownLanguageOpen] = useState(false)
  const [selectedLanguages, setSelectedLanguages] = useState([])
  const [languageOptions, setLanguageOptions] = useState<string[]>([])
  const handleOpenLanguageDropdown = () => {
    if (action == KolActions.VIEW) return
    setDropdownLanguageOpen(true)
  }
  const handleCloseLangugageDropdown = () => {
    setDropdownLanguageOpen(false)
  }

  const handleLanguageChange = (event: any) => {
    const {
      target: { value },
    } = event
    let targetLangugages = typeof value === "string" ? value.split(",") : value
    if (targetLangugages.includes(NO_LANGUAGE_SELECTED[0])) {
      targetLangugages = targetLangugages.filter((language: string) => language !== NO_LANGUAGE_SELECTED[0])
    }
    if (targetLangugages.length > MAX_LANGUAGE_SELECTIONS) {
      targetLangugages = targetLangugages.slice(0, MAX_LANGUAGE_SELECTIONS)
    }
    setSelectedLanguages(targetLangugages)
    setEditKolData({ ...editKolData, language: targetLangugages.join(", ") })
    handleCloseLangugageDropdown()
  }
  useEffect(() => {
    if (languageOptions.length > 0) return
    getLanguages().then((languages) => {
      setLanguageOptions(languages.map((language: any) => language.title))
    })
  })

  //Categories
  const [categoryOptions, setCategoryOptions] = useState<string[]>([])
  const MAX_CATEGORY_SELECTIONS = 2
  const NO_CATEGORY_SELECTED = ["Select up to 2 categories"]
  //Primary
  const [dropdownPrimaryCategoryOpen, setDropdownPrimaryCategoryOpen] = useState(false)
  const [selectedPrimaryCategories, setSelectedPrimaryCategories] = useState([])
  const handleOpenPrimaryCategoryDropdown = () => {
    if (action == KolActions.VIEW) return
    setDropdownPrimaryCategoryOpen(true)
  }
  const handleClosePrimaryCategoryDropdown = () => {
    setDropdownPrimaryCategoryOpen(false)
  }
  const handlePrimaryCategoryChange = (event: any) => {
    const {
      target: { value },
    } = event
    let targetCategories = typeof value === "string" ? value.split(",") : value
    if (targetCategories.includes(NO_CATEGORY_SELECTED[0])) {
      targetCategories = targetCategories.filter((language: string) => language !== NO_CATEGORY_SELECTED[0])
    }
    if (targetCategories.length > MAX_CATEGORY_SELECTIONS) {
      targetCategories = targetCategories.slice(0, MAX_CATEGORY_SELECTIONS)
    }
    setSelectedPrimaryCategories(targetCategories)
    setEditKolData({ ...editKolData, primary_category: targetCategories.join(", ") })
    handleClosePrimaryCategoryDropdown()
  }
  //Secondary
  const [dropdownSecondaryCategoryOpen, setDropdownSecondaryCategoryOpen] = useState(false)
  const [selectedSecondaryCategories, setSelectedSecondaryCategories] = useState([])
  const handleOpenSecondaryCategoryDropdown = () => {
    if (action == KolActions.VIEW) return
    setDropdownSecondaryCategoryOpen(true)
  }
  const handleCloseSecondaryCategoryDropdown = () => {
    setDropdownSecondaryCategoryOpen(false)
  }
  const handleSecondaryCategoryChange = (event: any) => {
    const {
      target: { value },
    } = event
    let targetCategories = typeof value === "string" ? value.split(",") : value
    if (targetCategories.includes(NO_CATEGORY_SELECTED[0])) {
      targetCategories = targetCategories.filter((language: string) => language !== NO_CATEGORY_SELECTED[0])
    }
    if (targetCategories.length > MAX_CATEGORY_SELECTIONS) {
      targetCategories = targetCategories.slice(0, MAX_CATEGORY_SELECTIONS)
    }
    setSelectedSecondaryCategories(targetCategories)
    setEditKolData({ ...editKolData, secondary_category: targetCategories.join(", ") })
    handleCloseSecondaryCategoryDropdown()
  }
  useEffect(() => {
    if (categoryOptions.length > 0) return
    getCategories().then((categories) => {
      setCategoryOptions(categories.map((category: any) => category.name))
    })
  })

  //Currency
  const [currencyOption, setCurrencyOption] = useState<string[]>([])
  const [dropdownCurrencyOpen, setDropdownCurrencyOpen] = useState(false)
  const [selectedCurrency, setSelectedCurrency] = useState("")
  const handleOpenCurrencyDropdown = () => {
    if (action == KolActions.VIEW) return
    setDropdownCurrencyOpen(true)
  }
  const handleCloseCurrencyDropdown = () => {
    setDropdownCurrencyOpen(false)
  }
  const handleCurrencyChange = (event: any) => {
    const {
      target: { value },
    } = event
    setSelectedCurrency(value)
    setEditKolData({ ...editKolData, currency: value })
    handleCloseCurrencyDropdown()
  }
  useEffect(() => {
    if (currencyOption.length > 0) return
    getCurrencies().then((currencies) => {
      setCurrencyOption(currencies.map((currency: any) => currency.name + " - " + currency.code))
    })
  })

  useEffect(() => {
    setEditKolData(kolData)
    setSelectedGender(`${kolData?.gender}`)
    setSelectedLanguages(kolData?.language?.split(", ") ?? [])
    setSelectedPrimaryCategories(kolData?.primary_category?.split(", ") ?? [])
    setSelectedSecondaryCategories(kolData?.secondary_category?.split(", ") ?? [])
    setSelectedCurrency(kolData?.currency ?? "")
  }, [kolData])

  if (role == "campaign-manager") {
    if (kolData && kolData.id != null) {
      action = KolActions.VIEW
      title = "KOL Details"
    } else {
      action = KolActions.ADD
      title = "Add KOL"
    }
  } else if (role === "general-manager" || role === "system-admin") {
    if (kolData && kolData.id != null) {
      action = KolActions.EDIT
      title = "Edit KOL"
    } else {
      action = KolActions.ADD
      title = "Add KOL"
    }
  }
  const viewOnly = action == KolActions.VIEW
  const textFieldColor = viewOnly ? "#C4C4C4" : "white"

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      sx={{
        maxWidth: "100%", // Ensures the dialog does not exceed the screen width
        width: "100vw",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ padding: 0 }}>
          <MyAppBar />

          {/* Back Button */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "50px",
              marginTop: "70px",
              paddingLeft: "20px",
            }}
          >
            <IconBack
              onClick={() => {
                onClose()
                resetData()
              }}
              style={{ width: "25xp", height: "25px" }}
            />
            <div style={{ fontSize: "20px", fontWeight: "normal", color: "black", marginLeft: "20px" }}>{`${title}`}</div>
          </div>

          {/* KOL Information */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "20px",
              paddingRight: "20px",
              backgroundColor: "#f4f4f4",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            <div style={{ fontSize: "15px", fontWeight: "bold", color: "black", paddingTop: "20px", paddingBottom: "20px" }}>
              KOL Information
            </div>
            {/* Name */}
            <div style={{ flexDirection: "column", width: `${window.innerWidth / 3}px`, paddingRight: "10px", fontSize: "12px" }}>
              Name
              <TextField
                sx={{ input: { color: "black" } }}
                variant="standard"
                value={editKolData?.name}
                fullWidth
                onChange={(e) => {
                  setEditKolData({ ...editKolData, name: e.target.value })
                }}
                InputProps={{
                  style: {
                    padding: "10px", // Adjust padding as needed
                  },
                }}
                disabled={viewOnly}
                style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
              />
              {/* A-List */}
              <div style={{ flexDirection: "column", width: `${window.innerWidth / 3}px`, marginTop: "10px", fontSize: "12px" }}>
                A-List
                <Checkbox
                  checked={editKolData?.a_list === ALIST.YES}
                  disabled={viewOnly}
                  onChange={(e) => {
                    setEditKolData({ ...editKolData, a_list: e.target.checked ? ALIST.YES : ALIST.NO })
                  }}
                />
              </div>
              {/* gender */}
              <div style={{ marginTop: "10px", padding: "10px", fontSize: "10px", display: "flex", flexDirection: "column" }}>
                Gender
                <FormControl component="fieldset" style={{ padding: "0px", margin: "0px" }} defaultValue={selectedGender}>
                  <div style={{ fontSize: "12px" }}>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="row-radio-buttons-group"
                      value={selectedGender}
                      onChange={handleGenderChange}
                    >
                      <FormControlLabel value="male" control={<Radio />} label="Male" disabled={action === KolActions.VIEW} />
                      <FormControlLabel value="female" control={<Radio />} label="Female" disabled={action === KolActions.VIEW} />
                      <FormControlLabel value="other" control={<Radio />} label="Other" disabled={action === KolActions.VIEW} />
                    </RadioGroup>
                  </div>
                </FormControl>
              </div>
              {/* contact & email */}
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{ flexDirection: "column", width: `${window.innerWidth / 3}px`, marginTop: "10px", fontSize: "12px" }}
                >
                  Contact
                  <TextField
                    sx={{ input: { color: "black" } }}
                    variant="standard"
                    value={editKolData?.contact}
                    fullWidth
                    onChange={(e) => {
                      setEditKolData({ ...editKolData, contact: e.target.value })
                    }}
                    InputProps={{
                      style: {
                        padding: "10px", // Adjust padding as needed
                      },
                    }}
                    disabled={viewOnly}
                    style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                  />
                </div>

                <div
                  style={{
                    flexDirection: "column",
                    width: `${window.innerWidth / 3}px`,
                    marginTop: "10px",
                    fontSize: "12px",
                    marginLeft: "10px",
                  }}
                >
                  Email
                  <TextField
                    sx={{ input: { color: "black" } }}
                    variant="standard"
                    value={editKolData?.email}
                    fullWidth
                    onChange={(e) => {
                      setEditKolData({ ...editKolData, email: e.target.value })
                    }}
                    InputProps={{
                      style: {
                        padding: "10px", // Adjust padding as needed
                      },
                    }}
                    disabled={viewOnly}
                    style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                  />
                </div>
              </div>
              {/* address */}
              <div style={{ flexDirection: "column", width: `${window.innerWidth / 3}px`, marginTop: "10px", fontSize: "12px" }}>
                Address
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={editKolData?.address}
                  fullWidth
                  onChange={(e) => {
                    setEditKolData({ ...editKolData, address: e.target.value })
                  }}
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
              {/* state & race   */}
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{ flexDirection: "column", width: `${window.innerWidth / 3}px`, marginTop: "10px", fontSize: "12px" }}
                >
                  State
                  <TextField
                    sx={{ input: { color: "black" } }}
                    variant="standard"
                    value={editKolData?.state}
                    fullWidth
                    onChange={(e) => {
                      setEditKolData({ ...editKolData, state: e.target.value })
                    }}
                    InputProps={{
                      style: {
                        padding: "10px", // Adjust padding as needed
                      },
                    }}
                    disabled={viewOnly}
                    style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                  />
                </div>

                <div
                  style={{
                    flexDirection: "column",
                    width: `${window.innerWidth / 3}px`,
                    marginTop: "10px",
                    fontSize: "12px",
                    marginLeft: "10px",
                  }}
                >
                  Race
                  <TextField
                    sx={{ input: { color: "black" } }}
                    variant="standard"
                    value={editKolData?.race}
                    fullWidth
                    onChange={(e) => {
                      setEditKolData({ ...editKolData, race: e.target.value })
                    }}
                    InputProps={{
                      style: {
                        padding: "10px", // Adjust padding as needed
                      },
                    }}
                    disabled={viewOnly}
                    style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                  />
                </div>
              </div>
              {/* language */}
              {/* <div style={{ flexDirection: "column", width: `${window.innerWidth / 3}px`, marginTop: "10px", fontSize: "12px" }}>
                Language
                <TextField sx={{ input: { color: 'black' } }}
                  variant="standard"
                  value={editKolData?.language}
                  fullWidth
                  onChange={(e) => {
                    setEditKolData({ ...editKolData, language: e.target.value });
                  }}
                  InputProps={{
                    style: {
                      padding: '10px', // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px", }}
                />
              </div> */}
              <div
                style={{
                  flexDirection: "column",
                  width: `${window.innerWidth / 3}px`,
                  marginLeft: "-10px",
                  marginTop: "10px",
                  fontSize: "12px",
                  position: "relative",
                }}
              >
                <div style={{ marginLeft: "10px" }}>Language</div>
                <FormControl fullWidth sx={{ m: 1 }}>
                  <Select
                    disabled={viewOnly}
                    labelId="multi-select-label"
                    id="multi-select"
                    multiple
                    style={{ backgroundColor: `${textFieldColor}` }}
                    open={dropdownLanguageOpen}
                    onClose={handleCloseLangugageDropdown}
                    value={selectedLanguages.length === 0 ? NO_LANGUAGE_SELECTED : selectedLanguages}
                    onChange={handleLanguageChange}
                    input={<FilledInput style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }} />}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {languageOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {
                  <Button
                    onClick={() => {
                      handleOpenLanguageDropdown()
                    }}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "transparent",
                      color: "transparent",
                      zIndex: 2,
                      // Ensure the text is not selectable to mimic the button being invisible
                      userSelect: "none",
                    }}
                  >
                    Select Options
                  </Button>
                }
              </div>
              {/* primary & secondary category */}
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{ flexDirection: "column", width: `${window.innerWidth / 3}px`, marginTop: "10px", fontSize: "12px" }}
                >
                  Primary Category
                  {/* <TextField sx={{ input: { color: 'black' } }}
                    variant="standard"
                    value={editKolData?.primary_category}
                    fullWidth
                    onChange={(e) => {
                      setEditKolData({ ...editKolData, primary_category: e.target.value });
                    }}
                    InputProps={{
                      style: {
                        padding: '10px', // Adjust padding as needed
                      },
                    }}
                    disabled={viewOnly}
                    style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px", }}
                  /> */}
                  <div
                    style={{
                      flexDirection: "column",
                      width: `${window.innerWidth / 6}px`,
                      marginLeft: "-10px",
                      fontSize: "12px",
                      position: "relative",
                    }}
                  >
                    <FormControl fullWidth sx={{ m: 1 }}>
                      <Select
                        disabled={viewOnly}
                        labelId="multi-select-label"
                        id="multi-select"
                        multiple
                        style={{ backgroundColor: `${textFieldColor}` }}
                        open={dropdownPrimaryCategoryOpen}
                        onClose={handleClosePrimaryCategoryDropdown}
                        value={selectedPrimaryCategories.length === 0 ? NO_CATEGORY_SELECTED : selectedPrimaryCategories}
                        onChange={handlePrimaryCategoryChange}
                        input={<FilledInput style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }} />}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {categoryOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {
                      <Button
                        onClick={() => {
                          handleOpenPrimaryCategoryDropdown()
                        }}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "transparent",
                          color: "transparent",
                          zIndex: 2,
                          // Ensure the text is not selectable to mimic the button being invisible
                          userSelect: "none",
                        }}
                      >
                        Select Options
                      </Button>
                    }
                  </div>
                </div>

                <div
                  style={{
                    flexDirection: "column",
                    width: `${window.innerWidth / 3}px`,
                    marginTop: "10px",
                    fontSize: "12px",
                    marginLeft: "10px",
                  }}
                >
                  Secondary Category
                  {/* <TextField sx={{ input: { color: 'black' } }}
                    variant="standard"
                    value={editKolData?.secondary_category}
                    onChange={(e) => {
                      setEditKolData({ ...editKolData, secondary_category: e.target.value });
                    }}
                    fullWidth
                    InputProps={{
                      style: {
                        padding: '10px', // Adjust padding as needed
                      },
                    }}
                    disabled={viewOnly}
                    style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px", }}
                  /> */}
                  <div
                    style={{
                      flexDirection: "column",
                      width: `${window.innerWidth / 6}px`,
                      marginLeft: "-10px",
                      fontSize: "12px",
                      position: "relative",
                    }}
                  >
                    <FormControl fullWidth sx={{ m: 1 }}>
                      <Select
                        disabled={viewOnly}
                        labelId="multi-select-label"
                        id="multi-select"
                        multiple
                        style={{ backgroundColor: `${textFieldColor}` }}
                        open={dropdownSecondaryCategoryOpen}
                        onClose={handleCloseSecondaryCategoryDropdown}
                        value={selectedSecondaryCategories.length === 0 ? NO_CATEGORY_SELECTED : selectedSecondaryCategories}
                        onChange={handleSecondaryCategoryChange}
                        input={<FilledInput style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }} />}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {categoryOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {
                      <Button
                        onClick={() => {
                          handleOpenSecondaryCategoryDropdown()
                        }}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "transparent",
                          color: "transparent",
                          zIndex: 2,
                          // Ensure the text is not selectable to mimic the button being invisible
                          userSelect: "none",
                        }}
                      >
                        Select Options
                      </Button>
                    }
                  </div>
                </div>
              </div>
              <div style={{ height: "20px" }}></div>
            </div>
          </div>
          <div style={{ height: "20px" }}></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "20px",
              paddingRight: "20px",
              backgroundColor: "#f4f4f4",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            <div style={{ fontSize: "15px", fontWeight: "bold", color: "black", paddingTop: "20px", paddingBottom: "20px" }}>
              Social Account
            </div>
            {/* Instagram */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                }}
              >
                Platform
                <TextField
                  sx={{ input: { color: "black" } }}
                  disabled={true}
                  variant="standard"
                  value="Instagram"
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  style={{ backgroundColor: "#C4C4C4", fontSize: "12px" }}
                />
              </div>

              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Handle
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={findSocialHandle("INSTAGRAM", editKolData)?.social_platform_handle}
                  onChange={(e) => {
                    updateSocialPlatformHandle("INSTAGRAM", e.target.value)
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Followers
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={findNumberOfFollowers("INSTAGRAM", editKolData)}
                  onChange={(e) => {
                    const followersInString = e.target.value.replaceAll(",", "")
                    updateSocialPlatformFollower("INSTAGRAM", Number(followersInString))
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Tier
                <TextField
                  sx={{ input: { color: "black" } }}
                  disabled={true}
                  variant="standard"
                  value={findSocialTier("INSTAGRAM")}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  style={{ backgroundColor: "#C4C4C4", fontSize: "12px" }}
                />
              </div>
            </div>
            {/* Tiktok */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                }}
              >
                Platform
                <TextField
                  sx={{ input: { color: "black" } }}
                  disabled={true}
                  variant="standard"
                  value="TikTok"
                  onChange={(e) => {
                    updateSocialPlatformHandle("TIKTOK", e.target.value)
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  style={{ backgroundColor: "#C4C4C4", fontSize: "12px" }}
                />
              </div>

              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Handle
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={findSocialHandle("TIKTOK", editKolData)?.social_platform_handle}
                  fullWidth
                  onChange={(e) => {
                    updateSocialPlatformHandle("TIKTOK", e.target.value)
                  }}
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Followers
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={findNumberOfFollowers("TIKTOK", editKolData)}
                  onChange={(e) => {
                    const followersInString = e.target.value.replaceAll(",", "")
                    updateSocialPlatformFollower("TIKTOK", Number(followersInString))
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Tier
                <TextField
                  sx={{ input: { color: "black" } }}
                  disabled={true}
                  variant="standard"
                  value={findSocialTier("TIKTOK")}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  style={{ backgroundColor: "#C4C4C4", fontSize: "12px" }}
                />
              </div>
            </div>

            {/* XHS */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                }}
              >
                Platform
                <TextField
                  sx={{ input: { color: "black" } }}
                  disabled={true}
                  variant="standard"
                  value="Xiaohongshu"
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  style={{ backgroundColor: "#C4C4C4", fontSize: "12px" }}
                />
              </div>

              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Handle
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={findSocialHandle("XIAOHONGSHU", editKolData)?.social_platform_handle}
                  onChange={(e) => {
                    updateSocialPlatformHandle("XIAOHONGSHU", e.target.value)
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Followers
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={findNumberOfFollowers("XIAOHONGSHU", editKolData)}
                  onChange={(e) => {
                    const followersInString = e.target.value.replaceAll(",", "")
                    updateSocialPlatformFollower("XIAOHONGSHU", Number(followersInString))
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Tier
                <TextField
                  sx={{ input: { color: "black" } }}
                  disabled={true}
                  variant="standard"
                  value={findSocialTier("XIAOHONGSHU")}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  style={{ backgroundColor: "#C4C4C4", fontSize: "12px" }}
                />
              </div>
            </div>
            {/* Lemon8 */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                }}
              >
                Platform
                <TextField
                  sx={{ input: { color: "black" } }}
                  disabled={true}
                  variant="standard"
                  value="Lemon8"
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  style={{ backgroundColor: "#C4C4C4", fontSize: "12px" }}
                />
              </div>

              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Handle
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={findSocialHandle("LEMON8", editKolData)?.social_platform_handle}
                  onChange={(e) => {
                    updateSocialPlatformHandle("LEMON8", e.target.value)
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Followers
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={findNumberOfFollowers("LEMON8", editKolData)}
                  onChange={(e) => {
                    const followersInString = e.target.value.replaceAll(",", "")
                    updateSocialPlatformFollower("LEMON8", Number(followersInString))
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Tier
                <TextField
                  sx={{ input: { color: "black" } }}
                  disabled={true}
                  variant="standard"
                  value={findSocialTier("LEMON8")}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  style={{ backgroundColor: "#C4C4C4", fontSize: "12px" }}
                />
              </div>
            </div>
            {/* Youtube */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                }}
              >
                Platform
                <TextField
                  sx={{ input: { color: "black" } }}
                  disabled={true}
                  variant="standard"
                  value="YouTube"
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  style={{ backgroundColor: "#C4C4C4", fontSize: "12px" }}
                />
              </div>

              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Handle
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={findSocialHandle("YOUTUBE", editKolData)?.social_platform_handle}
                  onChange={(e) => {
                    updateSocialPlatformHandle("YOUTUBE", e.target.value)
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Followers
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={findNumberOfFollowers("YOUTUBE", editKolData)}
                  onChange={(e) => {
                    const followersInString = e.target.value.replaceAll(",", "")
                    updateSocialPlatformFollower("YOUTUBE", Number(followersInString))
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Tier
                <TextField
                  sx={{ input: { color: "black" } }}
                  disabled={true}
                  variant="standard"
                  value={findSocialTier("YOUTUBE")}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  style={{ backgroundColor: "#C4C4C4", fontSize: "12px" }}
                />
              </div>
            </div>
            {/* Facebook */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                }}
              >
                Platform
                <TextField
                  sx={{ input: { color: "black" } }}
                  disabled={true}
                  variant="standard"
                  value="Facebook"
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  style={{ backgroundColor: "#C4C4C4", fontSize: "12px" }}
                />
              </div>

              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Handle
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={findSocialHandle("FACEBOOK", editKolData)?.social_platform_handle}
                  onChange={(e) => {
                    updateSocialPlatformHandle("FACEBOOK", e.target.value)
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Followers
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={findNumberOfFollowers("FACEBOOK", editKolData)}
                  onChange={(e) => {
                    const followersInString = e.target.value.replaceAll(",", "")
                    updateSocialPlatformFollower("FACEBOOK", Number(followersInString))
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Tier
                <TextField
                  sx={{ input: { color: "black" } }}
                  disabled={true}
                  variant="standard"
                  value={findSocialTier("FACEBOOK")}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  style={{ backgroundColor: "#C4C4C4", fontSize: "12px" }}
                />
              </div>
            </div>
            <div style={{ height: "20px" }}></div>
          </div>
          <div style={{ height: "20px" }}></div>

          {/* KOL Rate */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "20px",
              paddingRight: "20px",
              backgroundColor: "#f4f4f4",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            <div style={{ fontSize: "15px", fontWeight: "bold", color: "black", paddingTop: "20px", paddingBottom: "20px" }}>
              KOL Rate
            </div>
            <div style={{ fontSize: "15px", fontWeight: "bold", color: "black", paddingTop: "20px", paddingBottom: "20px" }}>
              Instagram
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                }}
              >
                IG Photo
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={formatNumber(editKolData?.ig_photo ?? 0)}
                  onChange={(e) => {
                    setEditKolData({ ...editKolData, ig_photo: formatStringToValidNumber(e.target.value.replaceAll(",", "")) })
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>

              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                IG Multipost
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={formatNumber(editKolData?.ig_multipost ?? 0)}
                  onChange={(e) => {
                    setEditKolData({
                      ...editKolData,
                      ig_multipost: formatStringToValidNumber(e.target.value.replaceAll(",", "")),
                    })
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                IG Reels
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={formatNumber(editKolData?.ig_reels ?? 0)}
                  onChange={(e) => {
                    setEditKolData({ ...editKolData, ig_reels: formatStringToValidNumber(e.target.value.replaceAll(",", "")) })
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                IG Story
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={formatNumber(editKolData?.ig_story ?? 0)}
                  onChange={(e) => {
                    setEditKolData({ ...editKolData, ig_story: formatStringToValidNumber(e.target.value.replaceAll(",", "")) })
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
            </div>

            <div style={{ fontSize: "15px", fontWeight: "bold", color: "black", paddingTop: "20px", paddingBottom: "20px" }}>
              TikTok
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                }}
              >
                TikTok Video
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={formatNumber(editKolData?.tt_video ?? 0)}
                  onChange={(e) => {
                    setEditKolData({ ...editKolData, tt_video: formatStringToValidNumber(e.target.value.replaceAll(",", "")) })
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>

              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                TikTok Story
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={formatNumber(editKolData?.tt_story ?? 0)}
                  onChange={(e) => {
                    setEditKolData({ ...editKolData, tt_story: formatStringToValidNumber(e.target.value.replaceAll(",", "")) })
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
            </div>

            <div style={{ fontSize: "15px", fontWeight: "bold", color: "black", paddingTop: "20px", paddingBottom: "20px" }}>
              Xiaohongshu
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                }}
              >
                XHS Video
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={formatNumber(editKolData?.xhs_video ?? 0)}
                  onChange={(e) => {
                    setEditKolData({ ...editKolData, xhs_video: formatStringToValidNumber(e.target.value.replaceAll(",", "")) })
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                XHS Multipost
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={formatNumber(editKolData?.xhs_multipost ?? 0)}
                  onChange={(e) => {
                    setEditKolData({
                      ...editKolData,
                      xhs_multipost: formatStringToValidNumber(e.target.value.replaceAll(",", "")),
                    })
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
            </div>
            <div style={{ fontSize: "15px", fontWeight: "bold", color: "black", paddingTop: "20px", paddingBottom: "20px" }}>
              Lemon8
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                }}
              >
                Lemon8 Video
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={formatNumber(editKolData?.l8_video ?? 0)}
                  onChange={(e) => {
                    setEditKolData({ ...editKolData, l8_video: formatStringToValidNumber(e.target.value.replaceAll(",", "")) })
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Lemon8 Multipost
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={formatNumber(editKolData?.l8_multipost ?? 0)}
                  onChange={(e) => {
                    setEditKolData({
                      ...editKolData,
                      l8_multipost: formatStringToValidNumber(e.target.value.replaceAll(",", "")),
                    })
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
            </div>
            <div style={{ fontSize: "15px", fontWeight: "bold", color: "black", paddingTop: "20px", paddingBottom: "20px" }}>
              YouTube
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                }}
              >
                YouTube Video
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={formatNumber(editKolData?.yt_video ?? 0)}
                  onChange={(e) => {
                    setEditKolData({ ...editKolData, yt_video: formatStringToValidNumber(e.target.value.replaceAll(",", "")) })
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>

              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                YouTube Shorts
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={formatNumber(editKolData?.yt_shorts ?? 0)}
                  onChange={(e) => {
                    setEditKolData({ ...editKolData, yt_shorts: formatStringToValidNumber(e.target.value.replaceAll(",", "")) })
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
            </div>
            <div style={{ fontSize: "15px", fontWeight: "bold", color: "black", paddingTop: "20px", paddingBottom: "20px" }}>
              Facebook
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                }}
              >
                Facebook Photo
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={formatNumber(editKolData?.fb_photo ?? 0)}
                  onChange={(e) => {
                    setEditKolData({ ...editKolData, fb_photo: formatStringToValidNumber(e.target.value.replaceAll(",", "")) })
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>

              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Facebook Multipost
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={formatNumber(editKolData?.fb_multipost ?? 0)}
                  onChange={(e) => {
                    setEditKolData({
                      ...editKolData,
                      fb_multipost: formatStringToValidNumber(e.target.value.replaceAll(",", "")),
                    })
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Facebook Video
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={formatNumber(editKolData?.fb_video ?? 0)}
                  onChange={(e) => {
                    setEditKolData({ ...editKolData, fb_video: formatStringToValidNumber(e.target.value.replaceAll(",", "")) })
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
            </div>
            <div style={{ fontSize: "15px", fontWeight: "bold", color: "black", paddingTop: "20px", paddingBottom: "20px" }}>
              Additional
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                }}
              >
                Livestream
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={formatNumber(editKolData?.livestream ?? 0)}
                  onChange={(e) => {
                    setEditKolData({ ...editKolData, livestream: formatStringToValidNumber(e.target.value.replaceAll(",", "")) })
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>

              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Photo Shoot
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={formatNumber(editKolData?.photo_shoot ?? 0)}
                  onChange={(e) => {
                    setEditKolData({ ...editKolData, photo_shoot: formatStringToValidNumber(e.target.value.replaceAll(",", "")) })
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                }}
              >
                Video Shoot
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={formatNumber(editKolData?.video_shoot ?? 0)}
                  onChange={(e) => {
                    setEditKolData({ ...editKolData, video_shoot: formatStringToValidNumber(e.target.value.replaceAll(",", "")) })
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>

              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Event Attendance
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={formatNumber(editKolData?.event_attendance ?? 0)}
                  onChange={(e) => {
                    setEditKolData({
                      ...editKolData,
                      event_attendance: formatStringToValidNumber(e.target.value.replaceAll(",", "")),
                    })
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                }}
              >
                Content Usage
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={formatNumber(editKolData?.content_usage ?? 0)}
                  onChange={(e) => {
                    setEditKolData({
                      ...editKolData,
                      content_usage: formatStringToValidNumber(e.target.value.replaceAll(",", "")),
                    })
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                IG Story Highlights
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={formatNumber(editKolData?.ig_story_highlights ?? 0)}
                  onChange={(e) => {
                    setEditKolData({
                      ...editKolData,
                      ig_story_highlights: formatStringToValidNumber(e.target.value.replaceAll(",", "")),
                    })
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
            </div>
            <div style={{ height: "20px" }}></div>
            <div
              style={{
                flexDirection: "column",
                width: `${10 + (window.innerWidth * 36) / 100}px`,
                marginTop: "10px",
                fontSize: "12px",
              }}
            >
              Link in Bio
              <TextField
                sx={{ input: { color: "black" } }}
                variant="standard"
                value={editKolData?.link_in_bio}
                onChange={(e) => {
                  setEditKolData({ ...editKolData, link_in_bio: e.target.value })
                }}
                fullWidth
                InputProps={{
                  style: {
                    padding: "10px", // Adjust padding as needed
                  },
                }}
                disabled={viewOnly}
                style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
              />
            </div>
            <div style={{ height: "20px" }}></div>
          </div>
          <div style={{ height: "20px" }}></div>
          {/* Campaign Information */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "20px",
              paddingRight: "20px",
              backgroundColor: "#f4f4f4",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            <div style={{ fontSize: "15px", fontWeight: "bold", color: "black", paddingTop: "20px", paddingBottom: "20px" }}>
              Campaign Information
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                }}
              >
                CO NO
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={editKolData?.co_no}
                  fullWidth
                  onChange={(e) => {
                    setEditKolData({ ...editKolData, co_no: e.target.value })
                  }}
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>

              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Brand & Campaign Name
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={editKolData?.brand}
                  fullWidth
                  onChange={(e) => {
                    setEditKolData({ ...editKolData, brand: e.target.value })
                  }}
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Deliverables
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={editKolData?.deliverables}
                  fullWidth
                  onChange={(e) => {
                    setEditKolData({ ...editKolData, deliverables: e.target.value })
                  }}
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                KOL Campaign Rate (RM)
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={editKolData?.kol_rate}
                  onChange={(e) => {
                    setEditKolData({ ...editKolData, kol_rate: e.target.value })
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
              <div
                style={{
                  flexDirection: "column",
                  width: `${(window.innerWidth * 18) / 100}px`,
                  marginTop: "10px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                Currency
                <div
                  style={{
                    flexDirection: "column",
                    width: `${(window.innerWidth * 18) / 100}px`,
                    marginLeft: "-10px",
                    fontSize: "12px",
                    position: "relative",
                  }}
                >
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <Select
                      disabled={viewOnly}
                      labelId="multi-select-label"
                      id="multi-select"
                      style={{ backgroundColor: `${textFieldColor}` }}
                      open={dropdownCurrencyOpen}
                      onClose={handleCloseCurrencyDropdown}
                      value={selectedCurrency === "" ? "Select a currency" : selectedCurrency}
                      onChange={handleCurrencyChange}
                      input={<FilledInput style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }} />}
                      renderValue={(selected) => selected}
                    >
                      {currencyOption.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {
                    <Button
                      onClick={() => {
                        handleOpenCurrencyDropdown()
                      }}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "transparent",
                        color: "transparent",
                        zIndex: 2,
                        // Ensure the text is not selectable to mimic the button being invisible
                        userSelect: "none",
                      }}
                    >
                      Currency
                    </Button>
                  }
                </div>
              </div>
            </div>

            <div style={{ height: "20px" }}></div>
          </div>
          <div style={{ height: "20px" }}></div>
          {/* KOL Rating */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "20px",
              paddingRight: "20px",
              backgroundColor: "#f4f4f4",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            <div style={{ fontSize: "15px", fontWeight: "bold", color: "black", paddingTop: "20px", paddingBottom: "20px" }}>
              KOL Rating
            </div>
            {/* Name */}
            <div style={{ flexDirection: "column", width: `${window.innerWidth / 3}px`, paddingRight: "10px", fontSize: "12px" }}>
              {/* Content Creativity & Posting Punctuality & Responsiveness */}
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{ flexDirection: "column", width: `${window.innerWidth / 9}px`, marginTop: "10px", fontSize: "12px" }}
                >
                  Content Creativity
                  <TextField
                    sx={{ input: { color: "black" } }}
                    variant="standard"
                    value={editKolData?.content_creativity ?? 0}
                    onChange={(e) => {
                      let content_creativity = isNaN(Number(e.target.value)) ? 0 : Number(e.target.value)
                      if (content_creativity < 0) {
                        content_creativity = 0
                      }
                      if (content_creativity > 5) {
                        content_creativity = 5
                      }
                      const posting_punctuality = isNaN(Number(editKolData?.posting_punctuality))
                        ? 0
                        : Number(editKolData?.posting_punctuality)
                      const responsiveness = isNaN(Number(editKolData?.responsiveness)) ? 0 : Number(editKolData?.responsiveness)
                      const overall_rating = content_creativity + posting_punctuality + responsiveness

                      setEditKolData({
                        ...editKolData,
                        content_creativity: content_creativity,
                        overall_rating: overall_rating,
                        status: calculateStatusFromOverallRating(overall_rating),
                      })
                    }}
                    type="number"
                    fullWidth
                    InputProps={{
                      style: {
                        padding: "10px", // Adjust padding as needed
                      },
                    }}
                    disabled={viewOnly}
                    style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                  />
                </div>

                <div
                  style={{
                    flexDirection: "column",
                    width: `${window.innerWidth / 9}px`,
                    marginTop: "10px",
                    fontSize: "12px",
                    marginLeft: "10px",
                  }}
                >
                  Posting Punctuality
                  <TextField
                    sx={{ input: { color: "black" } }}
                    variant="standard"
                    value={editKolData?.posting_punctuality ?? 0}
                    onChange={(e) => {
                      let posting_punctuality = isNaN(Number(e.target.value)) ? 0 : Number(e.target.value)
                      if (posting_punctuality < 0) {
                        posting_punctuality = 0
                      }
                      if (posting_punctuality > 5) {
                        posting_punctuality = 5
                      }
                      const content_creativity = isNaN(Number(editKolData?.content_creativity))
                        ? 0
                        : Number(editKolData?.content_creativity)
                      const responsiveness = isNaN(Number(editKolData?.responsiveness)) ? 0 : Number(editKolData?.responsiveness)
                      const overall_rating = content_creativity + posting_punctuality + responsiveness
                      setEditKolData({
                        ...editKolData,
                        posting_punctuality: posting_punctuality,
                        overall_rating: overall_rating,
                        status: calculateStatusFromOverallRating(overall_rating),
                      })
                    }}
                    type="number"
                    fullWidth
                    InputProps={{
                      style: {
                        padding: "10px", // Adjust padding as needed
                      },
                    }}
                    disabled={viewOnly}
                    style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                  />
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    width: `${window.innerWidth / 9}px`,
                    marginTop: "10px",
                    fontSize: "12px",
                    marginLeft: "10px",
                  }}
                >
                  Responsiveness
                  <TextField
                    sx={{ input: { color: "black" } }}
                    variant="standard"
                    value={editKolData?.responsiveness ?? 0}
                    onChange={(e) => {
                      let responsiveness = isNaN(Number(e.target.value)) ? 0 : Number(e.target.value)
                      if (responsiveness < 0) {
                        responsiveness = 0
                      }
                      if (responsiveness > 5) {
                        responsiveness = 5
                      }
                      const content_creativity = isNaN(Number(editKolData?.content_creativity))
                        ? 0
                        : Number(editKolData?.content_creativity)
                      const posting_punctuality = isNaN(Number(editKolData?.posting_punctuality))
                        ? 0
                        : Number(editKolData?.posting_punctuality)
                      const overall_rating = content_creativity + posting_punctuality + responsiveness
                      setEditKolData({
                        ...editKolData,
                        responsiveness: responsiveness,
                        overall_rating: overall_rating,
                        status: calculateStatusFromOverallRating(overall_rating),
                      })
                    }}
                    type="number"
                    fullWidth
                    InputProps={{
                      style: {
                        padding: "10px", // Adjust padding as needed
                      },
                    }}
                    disabled={viewOnly}
                    style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                  />
                </div>
              </div>
              {/* Overal Rating */}
              <div style={{ flexDirection: "column", width: `${window.innerWidth / 3}px`, marginTop: "10px", fontSize: "12px" }}>
                Overal Rating
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  disabled={true}
                  value={editKolData?.overall_rating ?? 0}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  style={{ backgroundColor: "#c4c4c4", fontSize: "12px" }}
                />
              </div>
              {/* Status */}
              <div style={{ flexDirection: "column", width: `${window.innerWidth / 3}px`, marginTop: "10px", fontSize: "12px" }}>
                Status
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={editKolData?.status}
                  onChange={(e) => {
                    setEditKolData({ ...editKolData, status: e.target.value })
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={true}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>
              {/* Remark */}
              <div style={{ flexDirection: "column", width: `${window.innerWidth / 3}px`, marginTop: "10px", fontSize: "12px" }}>
                Remark
                <TextField
                  sx={{ input: { color: "black" } }}
                  variant="standard"
                  value={editKolData?.remarks}
                  onChange={(e) => {
                    setEditKolData({ ...editKolData, remarks: e.target.value })
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      padding: "10px", // Adjust padding as needed
                    },
                  }}
                  disabled={viewOnly}
                  style={{ backgroundColor: `${textFieldColor}`, fontSize: "12px" }}
                />
              </div>

              <div style={{ height: "20px" }}></div>
            </div>
            <div style={{ height: "20px" }}></div>
          </div>
          {action != KolActions.VIEW && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "20px",
                paddingLeft: "20px",
                width: "100%",
                height: "50px",
              }}
            >
              <div
                style={{
                  padding: "10px 20px",
                  fontSize: "14px",
                  backgroundColor: "#6750A4",
                  color: "white",
                  border: "none",
                  borderRadius: "15px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (editKolData.name == "" || editKolData.name == null || editKolData.name == undefined) {
                    alert("Please fill in KOL Name")
                    return
                  }
                  let numberOfPlatforms = 0
                  if (editKolData.kol_social_platforms) {
                    for (let platform of editKolData.kol_social_platforms) {
                      if (platform.social_platform_handle != "") {
                        numberOfPlatforms++
                      }
                    }
                  }

                  if (numberOfPlatforms == 0) {
                    alert("Please fill in at least one social media handle")
                    return
                  }
                  onSave(editKolData)
                  resetData()
                }}
              >
                Save
              </div>
              {action == KolActions.EDIT && (
                <div
                  style={{
                    padding: "10px 20px",
                    fontSize: "14px",
                    backgroundColor: "white",
                    color: "red",
                    border: "none",
                    borderRadius: "15px",
                    cursor: "pointer",
                    marginLeft: "20px",
                  }}
                  onClick={() => {
                    if (window.confirm("Are you sure you want to delete this KOL?")) {
                      onDelete(editKolData)
                      resetData()
                    }
                  }}
                >
                  Delete
                </div>
              )}
              {action == KolActions.ADD && (
                <div
                  style={{
                    padding: "10px 20px",
                    fontSize: "14px",
                    backgroundColor: "white",
                    color: "#6750A4",
                    border: "none",
                    borderRadius: "15px",
                    cursor: "pointer",
                    marginLeft: "20px",
                  }}
                  onClick={() => {
                    onClose()
                    resetData()
                  }}
                >
                  Cancel
                </div>
              )}
            </div>
          )}
          <div style={{ height: "20px" }}></div>
        </DialogContent>
      </form>
    </Dialog>
  )
}

function findNumberOfFollowers(platform: string, kol: any) {
  const follower =
    findSocialHandle(platform.toUpperCase(), kol)?.number_of_followers == 0 ||
    findSocialHandle(platform.toUpperCase(), kol)?.number_of_followers == null
      ? 0
      : findSocialHandle(platform.toUpperCase(), kol)?.number_of_followers

  if (follower == 0) {
    return ""
  } else {
    const result = formatNumber(follower)
    return result
  }
}

function findSocialHandle(platform: string, kol: any) {
  if (kol?.kol_social_platforms) {
    let kolPlatformInfo = kol.kol_social_platforms.find((handle: any) => handle.social_platform_code === platform)
    return kolPlatformInfo
  }
  return null
}

function calculateStatusFromOverallRating(rating: number) {
  if (rating <= 0) {
    return "Blacklisted"
  } else if (rating >= 1 && rating <= 4) {
    return "Poor"
  } else if (rating >= 5 && rating <= 9) {
    return "Neutral"
  } else if (rating >= 10 && rating <= 12) {
    return "Good"
  } else {
    return "Excellent"
  }
}

function formatStringToValidNumber(data: string) {
  const result = data.replace(/[^0-9,.]/g, "")
  return result
}

export function formatNumber(data: any): string | any {
  const number = parseFloat(formatStringToValidNumber(`${data}`))
  if (!isNaN(number)) {
    return number.toLocaleString(undefined, { maximumFractionDigits: 2 })
  } else {
    return data
  }
}
